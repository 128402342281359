import landingHeroDesktop from "./hero/hero-desktop-lrg.png"
import landingHeroMobile from "./hero/hero-mobile.png"

import storyIsland from "./book_tiles/BT1-desert.png"
import storyOperation from "./book_tiles/BT1-operation.png"
import storyPirate from "./book_tiles/BT1-pirates.jpg"
import storyCut from "./book_tiles/BT1-cut.jpg"
import storyPirates02 from "./book_tiles/BT_pirates-02.png"
import storyUnderground from "./book_tiles/BT_underground.png"
import storyTrapApp from "./book_tiles/BT_trapapp.png"
import storyBuried from "./book_tiles/BT_buried.png"
import storyCabinFever from "./book_tiles/BT_cabin_fever.png"

import plusFAQ from "./plusFAQ.svg"
import appStoreDownload from "./Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
import Arrow from "./Arrow.svg"
import mystery from "./chooseMystery.jpg"
import adventure from "./chooseAdventure.jpg"
import drama from "./chooseDrama.jpg"
import fear from "./chooseFear.jpg"
import romance from "./chooseRomance.jpg"
import pathsLogo from "./PathsLogo-v2.svg"
import pathsLogoSpaced from "./PathsLogo-NewSpace.png"
import hamburger from "./hamburger.svg"
import bg from "./half_black_bg.svg"
import close from "./Clear.svg"
import minusFAQ from "./minus-custom.svg"
import appStoreImage from "./img_download_appstore.png"
import playStoreImage from "./img_download_googleplay.png"

export default {
    landingHeroDesktop,
    landingHeroMobile,

    storyIsland,
    storyOperation,
    storyPirate,
    storyCut,
    storyPirates02,
    storyUnderground,
    storyTrapApp,
    storyBuried,
    storyCabinFever,

    playStoreImage,
    plusFAQ,
    appStoreDownload,
    Arrow,
    mystery,
    adventure,
    drama,
    fear,
    romance,
    pathsLogo,
    pathsLogoSpaced,
    hamburger,
    bg,
    close,
    minusFAQ,
    appStoreImage
}