/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import queryString from "query-string"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./Header/header.js"
import "./layout.css"
import styles from "./themes.module.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  let from_app = true;
  if (typeof window !== "undefined") {
    const search = window.location.search;
    const params = queryString.parse(search);
    from_app = params.from_app !== undefined && parseInt(params.from_app) === 1;
  }
  console.log(from_app)
  
  return (
    <>
      <div>
      {from_app ? null : <Header siteTitle={data.site.siteMetadata.title} />}
        <main>{children}</main>
        <footer className={styles.footerCustom}>
          <div className={styles.ptacContainer}>
            <span>
              <a className={styles.privacy} href={"/privacy"}>Privacy</a>
              <a className={styles.tac} href={"/terms"}>Terms & Conditions</a>
            </span>
          </div>
          <div className={styles.crContainer}>
            <span className={styles.copyright}>Paths Game Limited Copyright {new Date().getFullYear()}. All rights reserved</span>
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
