import { Link } from "gatsby"
import React from "react"
import styles from "./themes.module.css"
import Images from "../../images/images.js"
import hamburgers from 'hamburgers/dist/hamburgers.css';
const {close, bg, hamburger, pathsLogoSpaced} = Images;

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            size: null,
            menuContentVisibility: false
        };
        this.handleResize = this.handleResize.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.menuVisibility = this.menuVisibility.bind(this);
        this.jumpto = this.jumpto.bind(this);
    }

    componentDidMount() {
        this.setState({
            size: window.innerWidth
        });
        window.addEventListener('resize', this.handleResize);
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.addEventListener('resize', this.handleResize);
    }

    handleResize() {
        this.setState({
            size: window.innerWidth
        })
    }

    handleScroll() {
        if (window.pageYOffset > 0 && !this.state.solidBackground) {
            this.setState({
                solidBackground: true
            })
        }
        else if (window.pageYOffset <= 0 && this.state.solidBackground) {
            this.setState({
                solidBackground: false
            })
        }
    }

    menuVisibility() {
        this.setState({
            menuContentVisibility: !this.state.menuContentVisibility
        })
    }

    jumpto(section) {
        this.setState({
            menuContentVisibility: false
        });
        
        let currentLocation = window.location.pathname;
        if (currentLocation !== "/") {
            window.location.href = "/";
        }

        const element =  document.getElementById( section );
        const offset = 150;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;
        
        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
       });
    }

    render() {
        let navBarClass = this.state.solidBackground ? `${styles.navBarContainer} ${styles.scrolled}` : `${styles.navBarContainer}` ;
        let activePage = typeof window !== 'undefined' ? '/' + window.location.pathname.replace(/^\/([^\/]*).*$/, '$1') : 'home';
        let activePageCss = {color: '#f3a953'};
        let hamburgerWhite = "hamburger-inner " + styles.hamburgerinnerwhite;

        if (this.state.size < 770) {
            return (

                <div className={styles.navBarContainer}>
                    <div className={styles.logoCell}>
                        <Link to="/"><img src={pathsLogoSpaced} alt="Paths Logo" className={styles.pathsLogo}/></Link>
                    </div>
                    <button className={this.state.menuContentVisibility ?
                                       "hamburger hamburger--spin is-active" :
                                       "hamburger hamburger--spin"}
                            type="button" onClick={this.menuVisibility} style={{outline:'none'}}>
                        <span className="hamburger-box">
                            <span className={hamburgerWhite}/>
                        </span>
                    </button>
                    {this.state.menuContentVisibility ?
                        <div className={styles.menuContainer}>
                            <div className={styles.menuContent}>
                                <div className="spaceForHeader"> </div>
                                <div onClick={() => {this.jumpto("aboutPaths")}}>
                                    Download
                                </div>
                                <div onClick={() => {this.jumpto("aboutPaths")}}>
                                    About Paths
                                </div>
                                <div onClick={() => {this.jumpto("faq")}}>
                                    FAQs
                                </div>
                                <div>
                                    <Link to="/terms" style={activePage === "/terms" ? activePageCss : {}}>
                                        Terms &amp; Conditions
                                    </Link>
                                </div>
                                <div>
                                    <Link to="/privacy" style={activePage === "/privacy" ? activePageCss : {}}>
                                        Privacy
                                    </Link>
                                </div>
                                <div onClick={() => {this.jumpto("contact")}}>
                                    Get in touch
                                </div>
                            </div>
                        </div>
                    : null
                    }
                </div>
            )
        }
        return (
            <div className={navBarClass}>
                <div className={styles.logoCell}>
                    <Link to="/"><img src={pathsLogoSpaced} alt="Paths Logo" className={styles.pathsLogo}/></Link>
                </div>
                <div className={styles.headerCell} onClick={() => {this.jumpto("aboutPaths")}}>
                    Download
                </div>
                <div className={styles.headerCell} onClick={() => {this.jumpto("aboutPaths")}}>
                    About Paths
                </div>
                <div className={styles.headerCell} onClick={() => {this.jumpto("faq")}}>
                    FAQs
                </div>
                <div className={styles.headerCell}>
                    <Link to="/terms" style={activePage === "/terms" ? activePageCss : {}}>
                        Terms &amp; Conditions
                    </Link> 
                </div>
                <div href="/privacy" className={styles.headerCell}>
                    <Link to="/privacy" style={activePage === "/privacy" ? activePageCss : {}}>
                        Privacy
                    </Link>
                </div>
                <div className={styles.headerCell} onClick={() => {this.jumpto("contact")}} style={{paddingRight: '60px'}}>
                    Get in touch
                </div>
    
            </div>
        )
    }
}
  
  export default Header
